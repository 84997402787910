@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/relative/Kanit-Regular.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/relative/Kanit-Bold.ttf"); /* IE9 Compat Modes */
}

body {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-family: Kanit, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.recharts-wrapper {
  font-size: 0.8rem;
  font-family: Monaco, Consolas, monospace;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #000;
  background: #f6f9ff;
  line-height: 1.15;
  padding: 1rem;
  padding-top: 0;
  
}

.App.dark {
  background-color: black;
  color: #fff;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 68vh
}

h3,
h2 {
  color: #444;
  margin: 1rem 0 1.5rem;
}

.App.dark h3,
.App.dark h2 {
  color: white;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal;
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.chart-subtitle,
.stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
  max-width: 600px;

}
.title
{
  color: rgb(255, 255, 255);
    line-height: 1.5;
    text-align: center;
    font-size: 36px;
 
}
.tspan
{
  color: orange;
}
 .back-orangeBg {
  position: absolute;
  z-index: 0;
  background: linear-gradient(180deg,rgba(250,184,35,.6),rgba(236,99,15,.6));
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 310px;
  filter: blur(150px);
   
} 
.back-brownBg
{
  
  position: absolute;
  z-index: 0;
  background: linear-gradient(180deg,rgba(235,92,13,.6),rgba(133,45,0,.6));
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 310px;
  filter: blur(114px);
  top: 317px;
  right: 0%;

}
.App.dark .chart-description {
  color:#9597a1;
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}
.button-app {
  width: 100px;
  align-items: center;
  margin: auto;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  border: 5px solid transparent;
  color: #f4eeff;
  background-clip: padding-box;
  border-radius: 30px;
  background-color: #e86110;
  font-size: 14px;
  font-weight: 300;
}
/* button doc */
.button-doc {
  width: 90px;
  align-items: center;
  position: relative;
  padding: 9px;
  box-sizing: border-box;
  background-clip: padding-box;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 300;
  background-color: transparent;
  border: 2px solid #e86110;
  margin: 0px 1px 2px 7px;
}


.button-doc:hover
{
  opacity: 0.65;
}
.button-app:hover {
  opacity: 0.65;
}
.chart-grid {
  color: #16182e;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-value {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.total-stat-delta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
}

.total-stat-delta.plus {
  color: #46e3ae;
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: #727171;
}

.App.dark .chart-cell {
  background: black;
  color: white;
}

.App.dark .chart-cell.stats {
  padding: 16px 30px;
  border-radius: 12px;

  background: rgb(17, 17, 17);

  margin: 16px 0px 16px 0px;
}

.chart-cell {
  margin: 4px;
  border-radius: 12px;
  border: 1px solid #e6e6f7;
  background-color: black;
  position: relative;
  padding: 1rem;
  grid-column-start: span 5;
}

.chart-cell.stats {
  min-height: 60px;
  grid-column-start: span 2;
  text-align: center;
  
}
.chart-cell.experiment {
  border-color: #fbb;
}
.chart-cell.experiment:after {
  color: #f55;
  top: 1rem;
  right: 1rem;
  position: absolute;
  content: "(experimental)";
}

.chart-cell h3 {
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.chart-cell .csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.3rem;
  opacity: 0.5;
  display: inline-flex;
  vertical-align: middle;
}
.chart-cell:hover .csv-link {
  opacity: 0.5;
}
.chart-cell .csv-link:hover {
  opacity: 1;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.chart-controls {
  display: flex;
}

.chart-control-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 8px;
  padding: 8px 7px;
  color: #666;
  font-weight: bold;
  background: #eee;
  border-radius: 4px;
  transition: background-color 0.1s, color 0.1s;
  font-size: 14px;
}

.chart-control-checkbox:hover {
  color: #000;
}

.chart-control-checkbox.active {
  background-color: #609ade;
  color: #fff;
}

.App.dark .chart-control-checkbox {
  background: #333;
  color: #aaa;
}

.App.dark .chart-control-checkbox:hover {
  color: #fff;
}

.App.dark .chart-control-checkbox.active {
  background: #609ade;
  color: #fff;
}

.form input[type="date"],
.form input[type="text"] {
  border: 1px solid #aaa;
  border-radius: 3px;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #000;
  border: 1px solid black;
  box-sizing: border-box;
  background:#e86110;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 3px;
  padding: 3px 7px;
}
.form button:hover {
  opacity: 0.9;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1.5s infinite;
  opacity: 0.3;
}
.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -1.5em;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.warning,
.warning a {
  color: #e46b00;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem 0;
  height: 75px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #7c7c7c;
  background-color: white;
}

.App.dark .nav {
  background: black;
  border-bottom: 1px solid #7c7c7c;
}

.nav-logo {
  width: 32px;
  vertical-align: middle;
  margin: 0px 0.1rem 0 0.2rem;
  display: inline-flex;
}

.nav-logo img {
  width: 32px;
}

a,
a:active,
a:visited {
  color: #16182e;
}

.nav-link {
  letter-spacing: 0.47px;
  color: #f4eeff;
  text-decoration: none;

  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.125rem;
  letter-spacing: 0.47px;
}

.nav-link:active,
.nav-link:visited {
  color: #f4eeff;
}

.nav-link.active {
  color: #444;
}

.App.dark a,
.App.dark a:active,
.App.dark a:visited {
  color: #ffffff;
}

.App.dark .nav-link {
  color: #f4eeff;
}

.App.dark .nav-link:active,
.App.dark .nav-link:visited {
  color: #f4eeff;
}

.App.dark .nav-link.active {
  color: white;
}

.App.dark .nav-link:hover {
  color: white;
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: #16182e 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: #a0a3c4;
}

.App.dark .modeselect svg {
  fill: #16182e;
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: #f4eeff;
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-grid-horizontal line,
.App.dark .recharts-cartesian-grid-vertical line {
  stroke: #282a3e;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 5px 12px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px !important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #353651 !important;
  box-shadow: 0px 3px 6px #0000005c;
  border: 1px solid #484b6e !important;
  border-radius: 4px;
}

.App-header-drawer {
  background: #101124 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #a0a3c4;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  font-size: 1.3rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }
  
}

.content {
  margin-top: 1rem;
}

.App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.date-range-selector-wrapper > div {
  display: flex;
  justify-content: flex-end;
}

.react-dropdown-select-dropdown {
  position: absolute;
  top: 43px;
  right: 0px;
  left: unset !important;
  border: 1px solid #ccc;
  width: 348px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  overflow: auto;
  z-index: 9;
  max-height: 475px !important;
  width: 270px !important;
}

.App.dark .react-dropdown-select-dropdown {
  background-color: #111 !important;
  border: 1px solid #e6e6f7 !important;
 border-radius:12px;
}

.react-dropdown-select {
  border: 1px solid #e6e6f7 !important;
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  padding: 2px 5px;
  flex-direction: row;
  direction: ltr;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
  max-width: fit-content;
  min-width: 215px !important;
  background-color:#353547;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.react-dropdown-select-dropdown-handle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.App.dark .react-dropdown-select {
  border: 1px solid #ffffff14 !important;
  background-color: #353547;
  width: 200px;
  border-radius: 10px;
}

.date-range-items {
  overflow: hidden;
  min-height: 10px;
  max-height: 200px;
}

.date-range-item {
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  align-items: baseline;
}

.date-range-item__label {
  color:#9597a1;
}

.date-range-item.selected .date-range-item__label,
.date-range-item:hover .date-range-item__label {
  color: #444;
}

.App.dark .date-range-item__label {
  color:#9597a1;
}

.App.dark .date-range-item.selected .date-range-item__label,
.App.dark .date-range-item:hover .date-range-item__label {
  color: white;
}

.date-range-custom {
  color: #000;
  margin: 0.5rem;
  text-align: center;
}

.react-dropdown-select-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.chart-cell.mobile {
  display: none;
}

.date-range-selector-wrapper .rdrDay {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}


.date-range-selector-wrapper .rdrMonth {
  width: 240px;
  background-color: #111  ;
}
.rdrNextPrevButton:hover {
  opacity: 0.9;
}
.date-range-selector-wrapper .rdrMonthAndYearPickers select {
  padding: 10px;
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrMonthAndYearPickers select {
  color: #bdbdbd;
  background: #111;
}

.date-range-selector-wrapper .rdrNextPrevButton {
  padding: 0;
}

.date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: orange;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
  background-color: #111;
}
.App.dark .date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #16182e;
}

.date-range-selector-wrapper .rdrWeekDay {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrWeekDay {
  color: white;
}

.date-range-selector-wrapper .rdrDayNumber span {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrDayNumber span {
  color: white;
}

.date-range-selector-wrapper .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.rdrDayPassive {
  opacity: 0.5;
}

.page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-title-section h1 {
  margin: 4px;
}

.page-description {
  margin: 0;
}

@media (max-width: 1033px) {
  .App-header-link-main img {
    height: 1.3125rem;
  }
}

@media all and (max-width: 600px) {
  .page-title-section {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }

  .page-title-section .form {
    margin-top: 1rem;
  }

  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset !important;
    grid-column-end: unset !important;
  }

  .chart-cell.mobile {
    display: block;
  }

  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }

  .react-dropdown-select {
    max-width: 100%;
  }
  .back-brownBg
  {
    position: absolute;
    z-index: 0;
    background:linear-gradient(180deg,rgba(235,92,13,.6),rgba(133,45,0,.6));
    width: 67.197px;
    height: 67.197px;
    flex-shrink: 0;
    border-radius: 67.197px;
    filter: blur(43.3526px);
    top: 600px;
    left:110%;
  }

}

@media (max-width: 450px) {

 
  .App-header-menu-icon {
    margin: 4px 5px !important;
  }
  .page-title-section {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }
  .chart-cell.stats {
    margin: 15px 14px 16px 0px;
  }
}
